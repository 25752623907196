import { Form, Input, Row, Col, Switch, Select } from 'antd';
import { useEffect, useRef, useState } from 'react';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx';
import fetchApi from '../../../utils/api/fetchApi';
import getList from "../../universal/getList";

//布局
const layout = {
    // labelCol: { span: 8 },
    // wrapperCol: { span: 16 }, 
};

const WebEdit = (props: any) => {
    const [form] = Form.useForm(); //form 
    const { data } = props;
    const ref = useRef<any>();

    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    /** 后端数据defaultService */
    let defaultService: any = '';

    const [wxSubscribeMessageNotifyTypeData, setWxSubscribeMessageNotifyTypeData] = useState<any>();
    const [wxSubscribeMessageV2UserData, setWxSubscribeMessageV2UserData] = useState<any>();

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            defaultService = ref.current.defaultService;
            ref.current.row.then((res: any) => {
                // console.log(res);

            })
        }
        const fetchData = async () => {
            fetchApi.GET(defaultService,
                {
                    apiId: 'generalData',
                    apiVariable: 'wxSubscribeMessageNotifyType',
                    apiExtend: 'showList'
                }).then(res => {
                    if (res.success) {
                        setWxSubscribeMessageNotifyTypeData(res.data);
                    }
                })
            fetchApi.GET(defaultService,
                {
                    apiId: 'wxSubscribeMessageV2User',
                    apiExtend: 'showList'
                }).then(res => {
                    if (res.success) {
                        setWxSubscribeMessageV2UserData(res.data);
                    }
                })
        }
        fetchData();
    }, []);

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'wxSubscribeMessageV2',
        serviceId: 'message'
    };

    return (
        <><WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '微信订阅信息'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={700}>

                <Form
                    {...layout}
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                // onKeyPress={(e: any) => {
                //     if (e.key === 'Enter') {
                //         handleOk(e)
                //     }
                // }}
                >
                    <Row gutter={[8, 0]}>

                        <Col span={6}>
                            <Form.Item
                                label="id"
                                name="id">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="订阅用户"
                                name="wxSubscribeMessageV2UserId"
                                rules={[{ required: true, message: '订阅者!' }]}>
                                <Select
                                    showSearch
                                    placeholder="wxSubscribeMessageV2UserId"
                                    optionFilterProp="children"
                                    onSelect={(e, a) => {
                                        console.log(e);
                                        console.log(a.children);
                                    }}
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.generalEx(wxSubscribeMessageV2UserData, {
                                        itemName: 'wxOpenId'
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="状态id"
                                name="curStatus">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="信息"
                                name="contentJson"
                                rules={[{ required: true, message: '信息!' }]}>
                                <Input.TextArea
                                    placeholder="信息"
                                    rows={4}>
                                </Input.TextArea>
                            </Form.Item>
                        </Col>

                        <Col span={20}>
                            <Form.Item
                                label="错误"
                                name="errorMessage">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={4}>
                            <Form.Item
                                label="发送"
                                name="isSend"
                                valuePropName="checked"
                                initialValue={true}>
                                < Switch checked={true}
                                    checkedChildren="成功"
                                    unCheckedChildren="失败" />
                            </Form.Item>
                        </Col>

                    </Row>
                </Form>
            </AntDraggableModal>
        </>
    )
}

export default WebEdit;