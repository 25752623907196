import CronTasksEdit from './CronTasksEdit';
import WebUniversal, { WUExtendPropsType } from '../WebUniversalNewEx'
import { columnsheadId } from '../../universal/columnshead';
import dayjs from 'dayjs';
import { Switch, Tooltip } from 'antd';

const CronJob = (props: any) => {

    /** api服务id */
    const apiId = 'cronTasks';

    //表头
    const columnsHead = [
        {
            title: '任务名',
            dataIndex: 'taskName',
        },
        {
            title: '类型',
            dataIndex: 'taskType_Name',
        },
        {
            title: '作业id',
            dataIndex: 'jobId',
            sorter: true,
        },
        {
            title: '定时方式',
            dataIndex: 'expression',
            render: (text: any) => <Tooltip placement="top" title={'@使用 ( * * * * * * ) 秒：0-59；分钟：0-59；小时：0-23；天：1-31；月份：0-11（1月至12月）；星期几：0-6（周日至周六）；' +
                '@提示 (*)为通配符；(-)为时间段连接符；(,)号为分隔符，可以在某一节输入多个值；(/)号为步进符；' +
                '@例子1) (1 * * * * *)：每分钟第一秒执行；2) (*/3 * * * * *)：间隔3秒执行;'}>
                {text}
            </Tooltip>,
        },
        {
            title: '开始时间',
            dataIndex: 'startDate',
            sorter: true,
            render: (val: dayjs.ManipulateType, record: any) =>
                <span>
                    {dayjs(val).format('YYYY-MM-DD HH:mm:ss')}
                </span>,
        },
        {
            title: '结束时间',
            dataIndex: 'endDate',
            sorter: true,
            render: (val: dayjs.ManipulateType, record: any) =>
                <span>
                    {dayjs(val).format('YYYY-MM-DD HH:mm:ss')}
                </span>,
        },
        {
            title: '流程',
            dataIndex: 'exeProcess_Name',
        },
        {
            title: '成功',
            dataIndex: 'isExeSuccess',
            render: (text: any, record: any) => (
                <Switch checkedChildren="是" unCheckedChildren="否"
                    checked={text} key={record.id}
                />
            )
        },
    ];

    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '定时任务',
        apiId,
        columns,
        showStates: true,
        pagination: true,
        EditComponent: CronTasksEdit,
        showLogger: {
            loggerSingleDisplay: true,
            logger: { id: 'systemLogger' }
        },
    };

    return (
        <WebUniversal props={extendProps} />
    )
}

export default CronJob;
