import { Table, Switch, Select, Tooltip, Input, Space, Button } from 'antd';
import { useEffect, useState } from 'react';
import fetchApi from '../../../utils/api/fetchApi'
import antMessage from '../../../utils/extend/AntdNotification'
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import { columnsheadId } from '../../universal/columnshead';
import getList from '../../universal/getList';
import systematic from '../../../config/systematic';
import RoleOrUserLicenseExtend from './RoleOrUserLicenseExtend';

const RoleOrUserLicense = (props: any) => {

    /** 使用类型 userLicense or roleLicense */
    const useType = props.useType;
    const { info, closeModal } = props;
    const { roleOrUserId, itemName } = info
    /** 后端数据bmService */
    const bmService = systematic.bmService;
    const [rows, setRows] = useState<any>();
    const [licenseTypeData, setLicenseTypeData] = useState<any>();
    const [licenseTypeIdData, setLicenseTypeIdData] = useState<any>();
    const [licenseGroupData, setLicenseGroupData] = useState<any>();
    const [licenseGroupIdData, setLicenseGroupIdData] = useState<any>();
    const [currentData, setCurrentData] = useState(1);
    const [pageSizeData, setPageSizeData] = useState(10);
    const [totalData, setTotalData] = useState(0);
    const [queryData, setQueryData] = useState('');
    const [modalExtend, setModalExtend] = useState(false);
    const [extendInfo, setExtendInfo] = useState<any>();

    let title: any;
    switch (useType) {
        case 'userLicense':
            title = '用户 [ ' + itemName + ' ] 权限';
            break;
        case 'roleLicense':
            title = '角色 [ ' + itemName + ' ] 权限';
            break;
    };

    /** 数据刷新中 */
    let loading = false;

    /** 默认权根类型id */
    const defaultLicenseTypeId = 'web';

    useEffect(() => {
        setLicenseTypeIdData(defaultLicenseTypeId);
        setLicenseGroupIdData(-1);
        getRoleLicense(defaultLicenseTypeId,
            '-1',
            currentData,
            pageSizeData,
            queryData);

        const fetchData = async () => {
            fetchApi.GET(bmService,
                {
                    apiId: 'generalData',
                    apiVariable: 'licenseType',
                    apiExtend: 'showList'
                }).then(res => {

                    if (res.success) {
                        setLicenseTypeData(res.data);
                    }
                });
            fetchApi.GET(bmService,
                {
                    apiId: 'licenseGroup',
                    apiExtend: 'showList'
                }).then(res => {
                    if (res.success) {
                        setLicenseGroupData(res.data.concat({
                            icon: null,
                            id: -1,
                            licenseGroupName: "所有权限组",
                            licenseGroupSort: null,
                        }));
                    }
                });
        };
        fetchData();
        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据

    const handleOk = (e: any) => {
        closeModal();
    };

    const licenseTypeChange = (e: any) => {
        setLicenseTypeIdData(e);
        getRoleLicense(e,
            licenseGroupIdData,
            1,
            pageSizeData,
            queryData);
    };

    const licenseGroupChange = (e: any) => {
        setLicenseGroupIdData(e);
        getRoleLicense(licenseTypeIdData,
            e,
            1,
            pageSizeData,
            queryData);
    }

    //获取数据
    const getRoleLicense = async (licenseTypeId: string,
        licenseGroupId: string,
        current: number,
        pageSize: number,
        queryString: string) => {
        if (loading === true) { return }
        loading = true;
        fetchApi.GET(
            bmService,
            {
                apiId: useType,
                apiExtend: 'query',
                id: roleOrUserId,
                licenseTypeId,
                licenseGroupId,
                current,
                pageSize,
                queryString
            }).then(res => {
                if (res.success === true) {
                    setRows(res.data.rows);
                    setCurrentData(res.data.current);
                    setPageSizeData(res.data.pageSize);
                    setTotalData(res.data.total);
                    setQueryData(res.data.queryString)
                }
                loading = false;
            })
    };

    //改变数据
    const handleChange = async (id: number, columnName: string, isValue: boolean) => {
        const bodys = {
            apiId: useType,
            apiVariable: id,
            apiData: {
                columnName,
                isValue
            }
        }
        fetchApi.PUT(bmService, bodys).then(res => {
            antMessage('success', '编辑成功', 'success');
            getRoleLicense(licenseTypeIdData,
                licenseGroupIdData,
                currentData,
                pageSizeData,
                queryData);
        }).catch(err => {
            antMessage('error', '删编辑失败', err.message);
        })
    };

    // 表格分页属性
    const paginationProps = {
        showSizeChanger: true,
        showQuickJumper: false,
        showTotal: () => `共${totalData}条`,
        pageSize: pageSizeData,
        current: currentData,
        total: totalData,
        onShowSizeChange: (current: number, pageSize: number) =>
            getRoleLicense(licenseTypeIdData,
                licenseGroupIdData,
                current,
                pageSize,
                queryData),
        onChange: (current: number) =>
            getRoleLicense(licenseTypeIdData,
                licenseGroupIdData,
                current,
                pageSizeData,
                queryData),
    };

    /** 关闭extend */
    const onCloseModalExtend = () => {
        setModalExtend(false);
    };
    /** 打开extend */
    const openExtend = (record: any) => {
        setExtendInfo({
            id: record.id,
            licenseName: record.license.licenseName
        })
        setModalExtend(true);
    };

    /** 表头内容 */
    const columnsHead = [
        {
            title: '权限',
            dataIndex: "license",
            editable: true,
            render: (text: any) => <Tooltip placement="topLeft" title={text.memo}>{
                text.licenseName
            }</Tooltip>,
        },
        {
            title: '查看',
            dataIndex: 'isShow',
            editable: true,
            render: (text: any, record: any) => (
                <Switch checkedChildren="启用" unCheckedChildren="关闭"
                    checked={text} key={record.id}
                    onChange={(e) => handleChange(record.id, 'isShow', e)}
                />
            )
        },
        {
            title: '增加',
            dataIndex: 'isAdd',
            render: (text: any, record: any) => (
                <Switch checkedChildren="启用" unCheckedChildren="关闭"
                    checked={text} key={record.id}
                    onChange={(e) => handleChange(record.id, 'isAdd', e)}
                />
            )
        },
        {
            title: '更新',
            dataIndex: 'isUpdate',
            render: (text: any, record: any) => (
                <Switch checkedChildren="启用" unCheckedChildren="关闭"
                    checked={text} key={record.id}
                    onChange={(e) => handleChange(record.id, 'isUpdate', e)}
                />
            )
        },
        {
            title: '删除',
            dataIndex: 'isRemove',
            render: (text: any, record: any) => (
                <Switch checkedChildren="启用" unCheckedChildren="关闭"
                    checked={text} key={record.id}
                    onChange={(e) => handleChange(record.id, 'isRemove', e)}
                />
            )
        },
        {
            title: '扩展',
            dataIndex: 'apiExtend',
            render: (text: any, record: any) =>
                <Space size="small">
                    <Button size={"small"} onClick={() => openExtend(record)}>扩展</Button>
                </Space>
        },
    ];

    /** 表头 */
    const columns = columnsheadId
        .concat(columnsHead as []);

    return (
        <>
            <AntDraggableModal
                title={title}
                open={true}
                okText='确定'
                cancelButtonProps={{ style: { display: "none" } }}
                onOk={handleOk}
                closable={false}
                maskClosable={false}
                width={850} >
                <label>权限类型 :  </label>
                <Select
                    showSearch
                    defaultValue={defaultLicenseTypeId}
                    onChange={licenseTypeChange}
                    placeholder="权限类型"
                    optionFilterProp="children"
                    filterOption={(input, option: any) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                    {getList.general(licenseTypeData)}
                </Select>
                <span> - </span>
                <label>权限组 :  </label>
                <Select
                    showSearch
                    defaultValue="所有权限组"
                    onChange={licenseGroupChange}
                    placeholder="权限组"
                    optionFilterProp="children"
                    filterOption={(input, option: any) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                    {getList.general(licenseGroupData, 'licenseGroupName')}
                </Select>
                <span> - </span>
                <label>查询 :  </label>
                <Space>
                    <Input onKeyPress={(e: any) => {
                        if (e.key === 'Enter')
                            getRoleLicense(licenseTypeIdData,
                                licenseGroupIdData,
                                currentData,
                                pageSizeData,
                                queryData)
                    }}
                        onChange={(e: any) => {
                            setQueryData(e.target.value)
                        }}
                    />
                </Space>
                <Table
                    rowKey={row => row.id}
                    columns={columns}
                    dataSource={rows}
                    size="small"
                    pagination={paginationProps}
                />
                {modalExtend ? (<RoleOrUserLicenseExtend
                    closeModal={onCloseModalExtend}
                    info={extendInfo}
                    useType={useType} />)
                    : null}
            </AntDraggableModal>
        </>
    )
}

export default RoleOrUserLicense;
